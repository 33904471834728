export const ORDER_FOR_SALES_COMPONENTS = `
  fragment orderForSalesComponents on Order {
    id
    uid
    status
    totalPrice
    creationTime
    currency
    paymentIntentId
    exhibitor {
      name
      addresses {
        address1
      }
      country {
        name
      }
    }
    orderPackages {
      quantity
      salesPackage {
        title
        price
        salesPackageComponent {
          salesComponent {
            uid
            type
            strategy
            updateTime
          }
        }
      }
    }
  }
`;
